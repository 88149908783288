export const releaseNoteAllEn = [
  [
    {
      releaseDate: "2025-02-20T17:00:00",
      updatedDate: "2025-02-20T17:00:00",
      version: "10.2.7",
      introduction: "We are constantly working to improve your experience with our infoceanus tools. Here is a summary of some of our newest changes to connect:",
      conclusion: "We always value your feedback. If you have any suggestions for improvement or other feedback for our infoceanus tools, please send us an email at connect-support@jrcs.co.jp. Happy sailing!",
    },
    {
      title: "Improvements",
      descriptions: [
        "Updated display of Navigation Data and Compass on Navigation Data Page and Cargo Tank Page"
      ],
      images: [],
    },
  ],
  [
    {
      releaseDate: "2025-01-28T16:00:00",
      updatedDate: "2025-01-28T16:00:00",
      version: "10.2.6",
      introduction: "We are constantly working to improve your experience with our infoceanus tools. Here is a summary of some of our newest changes to connect:",
      conclusion: "We always value your feedback. If you have any suggestions for improvement or other feedback for our infoceanus tools, please send us an email at connect-support@jrcs.co.jp. Happy sailing!",
    },
    {
      title: "Improvements",
      descriptions: [
        "Updated date timezone format on CSV file."
      ],
      images: [],
    },
  ],
  [
    {
      releaseDate: "2025-01-17T16:00:00",
      updatedDate: "2025-01-17T16:00:00",
      version: "10.2.5",
      introduction: "We are constantly working to improve your experience with our infoceanus tools. Here is a summary of some of our newest changes to connect:",
      conclusion: "We always value your feedback. If you have any suggestions for improvement or other feedback for our infoceanus tools, please send us an email at connect-support@jrcs.co.jp. Happy sailing!",
    },
    {
      title: "Improvements",
      descriptions: [
        "Updated release date format and Release Title for JP language.",
      ],
      images: [],
    },
  ],
  [
    {
      releaseDate: "2025-01-16T16:00:00",
      updatedDate: "2025-01-16T16:00:00",
      version: "10.2.4",
      introduction: "We are constantly working to improve your experience with our infoceanus tools. Here is a summary of some of our newest changes to connect:",
      conclusion: "We always value your feedback. If you have any suggestions for improvement or other feedback for our infoceanus tools, please send us an email at connect-support@jrcs.co.jp. Happy sailing!",
    },
    {
      title: "New features",
      descriptions: [
        "Release Notes page with version updates and history.",
        "Y-axis implementation on Custom Trend Graph for mixed data (analog and digital channels).",
      ],
      images: [require("./images/v10-2-4_fig1.png").default,],
    },
    {
      title: "Improvements",
      descriptions: [
        "Custom Graph chart display improvements on vessel switch via header selector.",
      ],
      images: [],
    },
  ],
  [
    {
      releaseDate: "2024-12-25T16:00:00",
      updatedDate: "2024-12-25T16:00:00",
      version: "10.2.3",
      introduction: "We are constantly working to improve your experience with our infoceanus tools. Here is a summary of some of our newest changes to connect:",
      conclusion: "We always value your feedback. If you have any suggestions for improvement or other feedback for our infoceanus tools, please send us an email at connect-support@jrcs.co.jp. Happy sailing!",
    },
    {
      title: "Improvements",
      descriptions: [
        "Improved performance on Graph zooming in and out for graphs with both Digital and Analog channel data.",
        "Improved display on Custom Trend Graph for all Digital channel data.",
      ],
      images: [],
    },
  ],
  [
    {
      releaseDate: "2024-12-19T16:00:00",
      updatedDate: "2024-12-19T16:00:00",
      version: "10.2.2",
      introduction: "We are constantly working to improve your experience with our infoceanus tools. Here is a summary of some of our newest changes to connect:",
      conclusion: "We always value your feedback. If you have any suggestions for improvement or other feedback for our infoceanus tools, please send us an email at connect-support@jrcs.co.jp. Happy sailing!",
    },
    {
      title: "Improvements",
      descriptions: [
        "Minor fix on engine data from v10.2.1.",
      ],
      images: [],
    },
  ],
  [
    {
      releaseDate: "2024-12-19T16:00:00",
      updatedDate: "2024-12-19T16:00:00",
      version: "10.2.1",
      introduction: "We are constantly working to improve your experience with our infoceanus tools. Here is a summary of some of our newest changes to connect:",
      conclusion: "We always value your feedback. If you have any suggestions for improvement or other feedback for our infoceanus tools, please send us an email at connect-support@jrcs.co.jp. Happy sailing!",
    },
    {
      title: "Improvements",
      descriptions: [
        "Custom trend graph display update to make all available channel types visible on Chart.",
        "Edit custom chart display improvement.",
        "Added custom graph loading icon.",
        "Trend Graph downloaded CSV file can now be downloaded on UTC and Local Time (JST) format.",
      ],
      images: [],
    },
  ],
  [
    {
      releaseDate: "2024-12-17T16:00:00",
      updatedDate: "2024-12-17T16:00:00",
      version: "10.2.0",
      introduction: "We are constantly working to improve your experience with our infoceanus tools. Here is a summary of some of our newest changes to connect:",
      conclusion: "We always value your feedback. If you have any suggestions for improvement or other feedback for our infoceanus tools, please send us an email at connect-support@jrcs.co.jp. Happy sailing!",
    },
    {
      title: "New features",
      descriptions: [
        "NEW! Multiple Fleet View per user configuration.",
        'NEW! Vessel parameters for Generator Fuel Oil Consumption:\nAdded fuel unit "KG", "TON", “GAL”.',
        "NEW Design for Channel Threshold Notification.",
        "NEW! Timezone picker in Header:\nChoose between UTC and LT (Local Time).\nTrend Graph DateTime display and download is based on the set Timezone on Header.",
        "Navigation Data Page NEW Design for True Wind and Relative Wind display.",
      ],
      images: [],
    },
    {
      title: "Improvements",
      descriptions: [
        "Performance Improvements:\nChannel Page data loading and response time for each vessel.\nData Comparison Page response time improvement.\nTrend Graph display loading time improvement.",
        "Updated configuration for Vessel Online / Offline Alert Emails.",
        "Improved Deleting and Editing Custom Graph functionalities.",
        "Image download: Graph Snapshot filename now indicates timezone selected.",
        "Alarm Status and Alarm Data Overview Display improved.",
        "Improved data download on 1-sec data (for specific vessels only).",
        "Third-party user API Requests Rate Limit updated.",
      ],
      images: [],
    },
  ],
];