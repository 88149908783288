import React, { useCallback, useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";

//Component imports
import Compass from "../compass/compass.jsx";
import WindPanel from "./windPanel.jsx";
import VesselInformationPanel from "./vesselInformationPanel.jsx";
import VesselImagePanel from "./vesselImagePanel.jsx";
import IteneraryPanel from "./iteneraryPanel.jsx";
import EngineDataPanel from "./engineDataPanel.jsx";
import SepDataPanel from "./sepDataPanel.jsx";

//API imports
import {
  fetchVesselLatestAisData,
  fetchVesselLatestGpggaData,
  fetchVesselLatestGpvtgData,
  fetchVesselLatestHehdtData,
  fetchVesselLatestMainEngineData,
  fetchVesselLatestWimwvrData,
  fetchVesselLatestWimwvtData,
} from "../../api/latestData.js";
import { fetchTrimAndHeelLatestData } from "../../api/latestSepData.js";

import { fetchVessel } from "../../api/vessel.js";

//Design imports
import { useTheme } from "@mui/material/styles";
import { Container, Paper, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import makeStyles from '@mui/styles/makeStyles';

//Localization Imports
import { useTranslation } from "react-i18next";
import localizationKeys from "../../i18n/localizationKeys.js";
import { useDispatch } from "react-redux";
import { useFocCalculation } from "./hooks/useFocCalculation.js";
import { useGenFocCalculation } from "./hooks/useGenFocCalculation.js";
import { useDetectVesselConnection } from "../../hooks/useDetectVesselConnection.js";
import { useInnerDict } from "../../hooks/useInnerDict.js";
import clsx from "clsx";
import useInterval from "../../hooks/useInterval.js";
import { useVesselSettings } from "../../helpers/vesselSetting/useVesselSettings.js";
import { logger } from "../../api/logger.js";
import MapWrapper from "../map/mapWrapper.jsx";
import {
  setMapCenter,
  setMapZoom,
  resetMapMarkers,
  resetCurrentVessels,
} from "../../model/slice/fleetNavigationDataSlice.js";
import { VESSEL_TYPE } from "../../constants/constants.js";
import { TabSelector } from "../tabSelector/tabSelector.jsx";
import { useParams } from "react-router-dom";

const navLabel = {
  fontWeight: "500",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
};

const commonTabs = (t) => [
  {
    name: t(localizationKeys.NavigationData_lower),
    id: "navigation",
  },
];

const useStyles = makeStyles((theme) => ({
  mapHolder: {
    height: "375px",
    margin: "0px -16px -16px -16px",
  },
  mapPaper: {
    height: "400px",
    overflow: "hidden", 
  },
  mapPaperTitle: {
    position: "absolute", 
    zIndex: 100, 
    left: "0", 
    right: "0", 
    top: "0", 
    margin: "0px", 
    backgroundColor:"rgba(36,43,54,0.9)", 
    borderRadius:"0px"
  },
  lgMapHolder: {
    margin: "0px",
    height: "100%",
    padding: "2px",
  },
  lgMapHolderFullHeight: {
    margin: "0px -16px -16px -16px",
    height: "100%",
  },
  lgAisCompass: {
    height: "600px",
  },
  lgAisCompassMiniHeight: {
    height: "573px",
  },
  vesselImagePanel: {
    height: "173px",
    borderRadius: "12px",
    overflow: "hidden",
  },
  engineDatPanel: {
    height: "230px",
  },
  navLabel: {
    ...navLabel,
    fontSize: "17px",
    color: "#19b2ab",
    [theme.breakpoints.down('lg')]: {
      fontSize: "13px",
    },
  },
  disconnectedNavLabel: {
    ...navLabel,
    color: "#c8c8c8",
  },
  navValue: {
    fontSize: "17px",
    fontWeight: "300",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    whiteSpace: "nowrap",
    color: "#ffffff",
    [theme.breakpoints.down('lg')]: {
      fontSize: "11px",
    },
  },
}));

const FETCH_INTERVAL = 1000 * 60; // 1 minute

const NavigationPage = (props) => {
  const { vesselId } = useParams() // Get vesselId from router
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const styles = useStyles();
  const theme = useTheme();
  const classes = { ...styles, ...props.layoutStyle };
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const { connections } = useDetectVesselConnection();
  const isDisconnected = connections.includes(vesselId);
  const paperTitle = isDisconnected ? classes.disconnectedPaperTitle : classes.paperTitle;
  const [mainEngineData, setMainEngineData] = useState([]);
  // const [gpggaData, setGpggaData] = useState({});
  const [gpvtgData, setGpvtgData] = useState({});
  const [hehdtData, setHehdtData] = useState({});
  const [aisData, setAisData] = useState({});
  const [vesselData, setVesselData] = useState({});
  const [wimwvrData, setWimwvrData] = useState({});
  const [wimwvtData, setWimwvtData] = useState({});
  const [trimheelData, setTrimHeelData] = useState({});
  const [center, setCenter] = useState();
  const { vesselSettings } = useVesselSettings({ vesselId });
  const [vesselMapData, setVesselMapData] = useState([]);
  const [isSelectedVesselSEP, setIsSelectedVesselSEP] = useState(false);
  const compassRef = useRef(null);
  const { width, height } = useInnerDict(compassRef);

  const focData = useFocCalculation({
    vesselId,
    // Removing hard cording, it is necessary to add flow meter unit info or unify flow meter unit in iAPI
    // flowmeterType: vesselSettings?.engineFoUnitFactor,
  });

  const focGenData = useGenFocCalculation({
    vesselId,
    flowmeterType: vesselSettings?.generatorFoUnitFactor,
  });

  const resetMarkers = () => {
    dispatch(resetCurrentVessels());
    dispatch(resetMapMarkers());
  };

  useEffect(() => {
    dispatch(setMapZoom(3));
    dispatch(setMapCenter(center));
    resetMarkers();
  }, [center]);

  useEffect(() => {
    dispatch(setMapZoom(5));
  }, []);

  const fetchValues = (init = false) => {
    Promise.all([
      fetchVessel(vesselId),
      fetchVesselLatestMainEngineData(vesselId),
      fetchVesselLatestGpggaData(vesselId),
      fetchVesselLatestGpvtgData(vesselId),
      fetchVesselLatestHehdtData(vesselId),
      fetchVesselLatestAisData(vesselId),
      fetchVesselLatestWimwvrData(vesselId),
      fetchVesselLatestWimwvtData(vesselId),
      fetchTrimAndHeelLatestData(vesselId),
    ])
      .then((data) => {
        if (data[0].vessel) {
          setVesselData(data[0].vessel);
          setIsSelectedVesselSEP(data[0].vessel.vesselType === VESSEL_TYPE.SEP);
        }
        if (data[1].latestMainEngineData[0]) {
          setMainEngineData(data[1].latestMainEngineData);
        }
        if (init) {
          setCenter({
            lat: data[2].latestGpggaData[0].data[0].latitude,
            lng: data[2].latestGpggaData[0].data[0].longitude,
          });
        }
        setVesselMapData([
          {
            vessel_id: data[0].vessel.id,
            ...data[0].vessel,
            vesselLatestAisData: data[5].latestAisData[0].data[0] ? [data[5].latestAisData[0].data[0]] : [],
            vesselLatestHehdtData: [data[4].latestHehdtData[0].data[0]],
            vesselLatestGpggaData: [data[2].latestGpggaData[0].data[0]],
          },
        ]);
        if (data[3].latestGpvtgData[0]) {
          setGpvtgData(data[3].latestGpvtgData[0].data[0]);
        }
        if (data[4].latestHehdtData[0]) {
          setHehdtData(data[4].latestHehdtData[0].data[0]);
        }
        if (data[5].latestAisData) {
          setAisData(data[5].latestAisData[0].data[0] || {});
        }
        if (data[6].latestWimwvrData[0]) {
          setWimwvrData(data[6].latestWimwvrData[0].data[0]);
        } else {
          setWimwvrData({});
        }
        if (data[7].latestWimwvtData[0]) {
          setWimwvtData(data[7].latestWimwvtData[0].data[0]);
        } else {
          setWimwvtData({});
        }
        if (data[8].latestTrimAndHeelData?.length > 0) {
          setTrimHeelData(data[8].latestTrimAndHeelData[0].data[0]);
        } else {
          setTrimHeelData({});
        }
      })
      .catch((e) => {
        logger.error(`fetchValues error: ${e}`);
      });
  };

  useEffect(() => {
    resetMarkers();
    fetchValues(true);
  }, [vesselId]);

  useInterval(fetchValues, FETCH_INTERVAL, false);

  const VesselPicturePanel = useCallback(() => {
    return (
      <VesselImagePanel aisData={aisData} vesselId={vesselId} isSEPvessel={isSelectedVesselSEP} />
    );
  }, [vesselId, aisData, isSelectedVesselSEP]);

  const _lgDisplayItinerary = (
    <Paper className={`${classes.paper}`} style={isLg ? {height: "233px"} : {}}>
      <Paper className={paperTitle}>
        <p>{t(localizationKeys.Itinerary_upper)}</p>
      </Paper>
      <IteneraryPanel classes={classes} aisData={aisData} isDisconnected={isDisconnected} />
    </Paper>
  );
  const _lgDisplayEngineData = isSelectedVesselSEP ? (
      <Paper className={classes.controlPaper} sx={{height: "233px"}}>
        <div className={classes.engineDatPanel}>
          <SepDataPanel
            classes={classes}
            sepData={trimheelData}
            focData={focGenData.currentFoc}
            isDisconnected={isDisconnected}
            foUnits={{
              generator: vesselSettings?.generatorFoUnit
            }}
          />
        </div>
      </Paper>
  ) : (
      <Paper className={classes.controlPaper} sx={{height: "233px"}}>
        <div className={classes.engineDatPanel}>
          <EngineDataPanel
            classes={classes}
            mainEngineData={mainEngineData}
            focData={focData.currentFoc}
            isDisconnected={isDisconnected}
            foUnits={{
              engine: vesselSettings?.engineFoUnit,
              generator: vesselSettings?.generatorFoUnit
            }}
          />
        </div>
      </Paper>
  );

  const lgDisplay = () => (
    <Grid container spacing={3}>
      <Grid lg={5} md={6} sm={12} direction="row">
          <Paper className={classes.paper} ref={compassRef} sx={{height: "100%", width:"100%", overflow:"hidden"}}>
            <div style={{display: "flex", flexDirection:"column", alignItems:"center", justifyContent:"center", height:"100%", gap:"20px", paddingTop:"80px"}}>
              <div style={{height:"0px", width:"80%", paddingBottom:"80%", position: "relative"}}> 
                <Compass
                  rotation={hehdtData.head}
                  speedKnot={gpvtgData.speedKnot}
                  twDirection={wimwvtData.direction}
                  rwDirection={wimwvrData.direction}
                  size={
                    width === 0 || Math.min(width, height) > 501
                      ? 437
                      : Math.min(width, height) * 0.85
                  }
                  isDisconnected={isDisconnected}
                />
              </div>
              <div style={{height:"40px"}}/>
              <div style={{width:"100%"}}>
                <WindPanel
                  heading={hehdtData.head}
                  speedOG={gpvtgData.speedKnot}
                  twSpeed={wimwvtData.force}
                  twDirection={wimwvtData.direction}
                  rwSpeed={wimwvrData.force}
                  rwDirection={wimwvrData.direction}
                />
              </div>
            </div>
          </Paper>
      </Grid>

      <Grid container lg={7} md={6} sm={12}>
        <Grid lg={6} md={12} sm={12}>
          <Paper className={classes.paper} sx={{height: "173px"}}>
            <Paper className={paperTitle}>
              <p>{t(localizationKeys.VesselInformation_upper)}</p>
            </Paper>
            <VesselInformationPanel
              classes={classes}
              aisData={aisData}
              vesselData={vesselData}
              isDisconnected={isDisconnected}
            />
          </Paper>
        </Grid>
        <Grid lg={6} md={12} sm={12}>
          <div className={classes.vesselImagePanel} >
            <VesselPicturePanel />
          </div>
        </Grid>
        <Grid lg={6} md={12} sm={12}>
          {_lgDisplayItinerary}
        </Grid>
        <Grid lg={6} md={12} sm={12}>
          {_lgDisplayEngineData}
        </Grid>
        <Grid xs={12}>
            <Paper className={clsx(classes.paper, classes.mapPaper)} style={{padding: "0px", position: "relative"}}>
              <Paper className={clsx(classes.paperTitle, classes.mapPaperTitle)} style={{}}>
                <p>{t(localizationKeys.CurrentPosition_upper)}</p>
              </Paper>
              <div className={classes.lgMapHolder} style={{ position: "relative", zIndex: 1 }}>
                <MapWrapper vessels={vesselMapData} />
              </div>
            </Paper>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <React.Fragment>
      {/* <NavBar title="NAVIGATION DATA" vesselName={"Test Vessel"} vesselType={"Test Type"} />  */}
      <Container maxWidth={false}>
        {/* for the time being navigation page doesn't have another tab */}
        <TabSelector tabs={commonTabs(t)} selectedOne={"navigation"} clickHandler={() => {}} />
        <div className={classes.root}>{lgDisplay()}</div>
      </Container>
    </React.Fragment>
  );
};

NavigationPage.propTypes = {
  vesselId: PropTypes.string,
};

export { NavigationPage };
