import React, { memo } from "react";
import PropTypes from "prop-types";
import dayjs from "util/dayjs-init.js";
import { Grid } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

// import { Weather } from "./weather.jsx";
import { COLORS } from "../../../constants/colors.js";
import { useTranslation } from "react-i18next";
import localizationKeys from "../../../i18n/localizationKeys.js";

const useStyles = makeStyles(() => ({
  container: {
    padding: "0 0 0 10px",
  },
  table: {
    marginTop: "10px",
    "& tr": {
      fontSize: "12px",
    },
    "& tr:first-child": {
      fontSize: "14px",
    },
    "& tr:not(:first-child)": {
      height: "20px",
    },
    "& tr td:first-child": {
      color: COLORS.white,
      width: "110px",
    },
    "& tr td:last-child": {
      color: COLORS.greenishBlue,
    },
  },
}));

export const Details = memo((props) => {
  const { gpggaData, gpvtgData, wimwvrData, wimwvtData } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        {/* <Weather lat={gpggaData?.[0]?.latitude} long={gpggaData?.[0]?.longitude} /> */}
      </Grid>
      <Grid item xs={12}>
        <table className={classes.table}>
          <tbody>
            <tr>
              <td>{t(localizationKeys.LocalTime)}</td>
              <td>{dayjs(gpggaData?.[0].dateTime).format("hh:mm z")}</td>
            </tr>
            <tr />
            <tr>
              <td>{t(localizationKeys.TrueWind)}</td>
              <td>
                {(wimwvtData?.data?.[0]?.direction ?? 0).toFixed(1)}° at {(wimwvtData?.data?.[0]?.force ?? 0).toFixed(1)}{" "}
                m/s
              </td>
            </tr>
            <tr>
              <td>{t(localizationKeys.RelativeWind)}</td>
              <td>
                {(wimwvrData?.data?.[0]?.direction ?? 0).toFixed(1)}° at {(wimwvrData?.data?.[0]?.force ?? 0).toFixed(1)}{" "}
                m/s
              </td>
            </tr>
            <tr />
            <tr>
              <td>{t(localizationKeys.VesselSpeedOG)}</td>
              <td>{(gpvtgData?.data?.[0]?.speedKnot ?? 0).toFixed(1)} knot</td>
            </tr>
            <tr>
              <td>{t(localizationKeys.LongAndLat)}</td>
              <td>
                {gpggaData?.[0]?.longitude?.toFixed(2) ?? "-"}°,{" "}
                {gpggaData?.[0]?.latitude?.toFixed(2) ?? "-"}°
              </td>
            </tr>
          </tbody>
        </table>
      </Grid>
    </Grid>
  );
});

Details.propTypes = {
  gpggaData: PropTypes.array,
  gpvtgData: PropTypes.object,
  wimwvrData: PropTypes.object,
  wimwvtData: PropTypes.object,
};
